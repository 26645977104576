import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import StatisticsCard from './StatisticsCard';

const Statistics = () => {
  return (
    <section id='statistics'>
      <Container>
        <div className='statistics-info'>
          <Row className="justify-content-md-center">
            <Col md={4}>
              <StatisticsCard title="Events Conducted" value="65" />
            </Col>
            <Col md={4}>
              <StatisticsCard title="Aid Delivered" value="$180K" />
            </Col>
            <Col md={4}>
              <StatisticsCard title="Featured Projects" value="24" />
            </Col>
          </Row>
        </div>
      </Container>
    </section>
  );
};

export default Statistics;
